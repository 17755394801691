<!--  -->
<template>
  <div>
    <div class="text-2xl text-center mt-48 text-gray-600">
      <i class="el-icon-loading"></i>
      正在进入SIM卡平台
    </div>
    <div
        @click="getSimManageHost"
        class="text-sm text-center mt-4 cursor-pointer"
    >
      如长时间未跳转，点击此处进入
    </div>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
export default {
  data () {
    return {}
  },
  methods: {
    getSimManageHost () {
      this.$api.sysUser.getSimManageHost().then(res => {
        if (res.data) {
          window.open(res.data)
        } else {
          this.$message.error('未获取到SIM卡平台地址')
        }
      })
    }
  },
  mounted () {
    this.getSimManageHost()
  }
}
</script>
